import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AuthPage from './Auth'
import { emailRegex } from "../utils";
import sendApiRequest from "../api";
import { Form, Message } from 'semantic-ui-react'

function SignInPage() {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false)
    const [errors, setErrors] = useState([]);
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {

        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);
        setErrors([])

        const formData = {
            email: email,
            password: password,
        };

        const newErrors = [];

        if (formData.email === "") {
            newErrors.push("Please enter your email address");
        } else if (!emailRegex.test(email)) {
            newErrors.push("Please enter a valid email address");
        }

        if (formData.password === "") {
            newErrors.push("Please enter your password");
        } else if (formData.password.length < 6) {
            newErrors.push("Password must be at least 6 characters long");
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        // Login request
        (async () => {
            const postData = await sendApiRequest("post", "/auth/login", formData);
            if (postData !== null) {
                if (postData.errors || postData.error) {
                    setApiErrors(true);
                    setApiSuccess(false);
                    setApiErrorMessage([postData.error]);
                }

                if (postData.token) {
                    setApiSuccess(true);
                    setApiSuccessMessage("You are now signed in. Redirecting in 1 second..");

                    const { token, user, expires } = postData;

                    localStorage.setItem("token", token);
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("loggedin", true);
                    localStorage.setItem("expires", expires);

                    return navigate("/chatbot");
                }
            } else {
                setApiErrorMessage(["Error occurred while submitting data to the API."]);
                setApiErrors(true);
                setApiSuccess(false);
                setLoading(false);
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <AuthPage>
            <h1 className='text-white text-center pt-10 text-lg'>Welcome to <b>DongMi!</b></h1>
            <h4 className='text-center text-white text-sm'>Kindly sign in or sign up for an account</h4>
            <div className="flex flex-col mt-5">

                <form className="rounded px-5 pt-5 pb-2 mb-1">

                    {errors.length > 0 && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-3" role="alert">
                            <strong className="font-bold">Whoops!</strong>
                            <ul>
                                {errors.map((error, index) => (
                                    <li key={index} className="text-sm">{error}</li>
                                ))}
                            </ul>
                            <button className="absolute top-0 right-0 px-3 py-1" onClick={() => setErrors([])}>x</button>
                        </div>
                   
                    )}

                    {apiErrors && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-3" role="alert">
                            <strong className="font-bold">Whoops!</strong>
                            <ul>
                                {apiErrorMessage.map((error, index) => (
                                    <li key={index} className="text-sm">{error}</li>
                                ))}
                            </ul>
                            <button className="absolute top-0 right-0 px-3 py-1" onClick={() => setErrors([])}>x</button>
                        </div>
                    )}

                    {apiSuccess && (
                        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-3" role="alert">
                            <strong className="font-bold">Authentication complete</strong>
                            <p className="text-sm">{apiSuccessMessage}</p>
                        </div>
                    )}

                    
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="username">
                            Email
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" id="username" type="email" placeholder="user@dongmi.shop" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input onChange={(e) => setPassword(e.target.value)} className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-gray-900 mb-1 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" />
                    </div>

                    <div className="mb-2">
                        <label className="flex items-center text-white text-sm font-bold mb-2">
                            <input type="checkbox" onChange={(e) => setRememberMe(e.target.value)} className="form-checkbox h-5 w-5 text-purple-500" />
                            <span className="ml-2 text-xs">Remember me</span>
                        </label>
                    </div>

                    <div className="flex items-center justify-between mb-4">
                        <button className="block w-full text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline text-xs" type="button">
                            <Link to={'/forgotpass'}>Reset Password</Link>
                        </button>
                    </div>
                    <div className="mb-2">
                        <button type='button' onClick={handleSubmit} className="block w-full bg-white text-black hover:bg-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline text-xs mb-2">
                            Sign In
                        </button>
                        <Link to={'/signup'} className="block w-full text-center bg-gradient-to-r from-purple-800 to-white-500 hover:bg-black text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline text-xs" type="button">
                            Sign Up
                        </Link>
                    </div>
                </form>
            </div>
            <br />
        </AuthPage>
    )
}

export default SignInPage