import React, { useState, useContext, useEffect } from 'react';
import { useLocalStorage } from "../Hooks/useLocalStorage";
import './Chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCamera, faVideo, faImage, faFile, faX } from '@fortawesome/free-solid-svg-icons';
import { IoMic, IoSend } from "react-icons/io5";
import { messageContext } from '../Context/MessageContext';
import moment from "moment";
import { useOnkeyPress } from '../Hooks/UseOnKeyPress';
import { RiHome2Line, RiLogoutBoxLine, RiSearch2Line, RiUser6Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { WS_URL } from '../api';

function Chat() {
  const [value, setValue] = useState('');
  const [modal, setModal] = useState(false);
  const { messages, setMessages } = useContext(messageContext);
  const [wsConnection, setWsConnection] = useState(null);
  const [jwtToken, setJwtToken] = useState('');

  useEffect(() => {
    const wsUrl = WS_URL;
    let connection = null;

    const connectWebSocket = () => {
      connection = new WebSocket(wsUrl);

      connection.onopen = () => {
        console.log('Connected to WebSocket');
      };

      connection.onerror = (error) => {
        console.error('Error occurred while connecting to WebSocket:', error);
        reconnectWebSocket();
      };

      connection.onmessage = ((message) => {
        console.log(`Received message: ${message.data}`);

        const response = JSON.parse(message.data);

        const newMessage = {
          text: response.response,
          sender: "chatbot",
          timeStamp: moment().format("MM/D/YYYY, h:mm:ss a")
        };

        const storedMessages = JSON.parse(localStorage.getItem("Messages")) || [];
        const updatedMessages = [...storedMessages, newMessage];
        setMessages(updatedMessages);
        localStorage.setItem("Messages", JSON.stringify(updatedMessages));
      });

      setWsConnection(connection);
    };

    const reconnectWebSocket = () => {
      setTimeout(() => {
        connectWebSocket();
      }, 3000); // Reconnect after 3 seconds
    };

    const handleBeforeUnload = () => {
      if (connection) {
        connection.close();
      }
    };

    const handleFocus = () => {
      if (!connection || connection.readyState !== WebSocket.OPEN) {
        connectWebSocket();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('focus', handleFocus);

    connectWebSocket();

    return () => {
      if (connection) {
        connection.close();
      }
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  const handleSendMessage = (event) => {
    if (wsConnection && wsConnection.readyState === WebSocket.OPEN && event.target.value) {
      wsConnection.send(event.target.value);
    } else {
      console.error('WebSocket connection is not open or is closing.');
    }
  };

  const toggleModal = () => {
    setModal(!modal);
  }

  function Submit() {
    const newMessage = {
      text: value,
      sender: "sender",
      timeStamp: moment().format("MM/D/YYYY, h:mm:ss a")
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);
    setValue('');
    localStorage.setItem("Messages", JSON.stringify([...messages, newMessage]));
  }

  function chatbotResponse() {
    if (wsConnection && wsConnection.readyState === WebSocket.OPEN && value) {
      wsConnection.send(JSON.stringify({ message: value.trim(), token: localStorage.getItem('token') }));
    } else {
      console.error('WebSocket connection is not open or is closing.');
    }
  }

  function finalSubmit() {
    if (value !== "") {
      Submit();
      chatbotResponse();
    }
  }

  const { setItem } = useLocalStorage('value');

  useOnkeyPress(finalSubmit, 'Enter');


  return (
    <>
      <div className="container1 bg-gray-100 shadow-xl mb-12" style={{ backgroundColor: "white" }}>
        <div className="add_btn bg-slate-500" onClick={toggleModal}>
          <FontAwesomeIcon icon={faPlus} style={{ color: "#f2f2f3" }} />
        </div>
        <input className='text_field' type="text" value={value} onChange={(e) => setValue(e.target.value)} name="chat" id="" placeholder="Type a message and hit enter" />
        <button className="send" onClick={finalSubmit}>
          <IoSend size="1.4rem" color="white" />
        </button>
        <button className="send" onClick={finalSubmit}>
          <IoMic size="1.5rem" color="white" />
        </button>
        <div className="mobile_menu fixed bottom-0 left-0 w-full bg-white  mb-0 pb-7">
          <ul className="flex justify-around py-2">
            <li><a href="/" className="text-gray-600 hover:text-gray-800 active"><RiHome2Line size="1.6rem" /></a></li>
            <li><a href="/about" className="text-gray-600 hover:text-gray-800"><RiSearch2Line size="1.6rem" /></a></li>
            <li><a href="/services" className="text-gray-600 hover:text-gray-800"><RiUser6Line size="1.6rem" /></a></li>
            <li><Link to="/logout" className="text-gray-600 hover:text-gray-800"><RiLogoutBoxLine size="1.6rem" /></Link></li>
          </ul>
        </div>
      </div>

      {modal && (
        <div className="poppup bg-gray-50 shadow-lg rounded-xl">
          <div className="close" onClick={toggleModal}>
            <FontAwesomeIcon icon={faX} size="xs" />
          </div>
          <h2 className="title">Help me understand your request better.</h2>
          <div className="files flex-col">
            <input type="file" name="" className='invisible' accept='image/*' id="image-selector" />
            <label htmlFor='image-selector'><FontAwesomeIcon icon={faCamera} className='icon' />Take a photo</label><br />
            <label htmlFor='image-selector'><FontAwesomeIcon icon={faVideo} className='icon' />Upload a video</label><br />
            <label htmlFor='image-selector'><FontAwesomeIcon icon={faImage} className='icon' />Upload from gallery</label><br />
            <label htmlFor='image-selector'><FontAwesomeIcon icon={faFile} className='icon' />Document</label>
          </div>
        </div>
      )}
    </>
  );
}

export default Chat;

