import React, { useEffect, useRef } from "react";
import "./Messages.css";
import avatar from "../img/avatar.png";

import { useState, useContext } from "react";
import { messageContext } from "../Context/MessageContext";

const Chat = ({ sender, avatarSrc, text, setText, textAreaRef, timeStamp }) => {
  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <>
      {sender === "chatbot" ? (
        <div className="chats">
          <div className="avatar">
            <img src={avatarSrc} alt="" />
          </div>
          <div className="chat_text">
            <p className="text">DongMi</p>
            <div
              className="chat_area max-w-7xl bg-white shadow resizable"
              onChange={handleChange}
              ref={textAreaRef}
              disabled
            >
              {text}
            </div>
            <p className="time">{timeStamp}</p>
          </div>
        </div>
      ) : (
        <div className="chats send">
          <div className="chat_text send ">
            <div
              className="chat_area1 shadow resizable"
              onChange={handleChange}
              rows="1"
              ref={textAreaRef}
              disabled
            >
              {text}
            </div>
            <p className="time">{timeStamp}</p>
          </div>
        </div>
      )}
    </>
  );
};

function Messages() {
  const messagesEndRef = useRef(null);
  const { messages, setMessages } = useContext(messageContext);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <>
      {messages.map((message, index) => (
        <Chat
          key={index}
          sender={message.sender}
          avatarSrc={avatar}
          text={message.text}
          setText={() => {}}
          textAreaRef={messagesEndRef}
          timeStamp={message.timeStamp}
        />
      ))}
      <br /><br /><br />
      <div ref={messagesEndRef} />
    </>
  );
}

export default Messages;
