import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css'
import App from './App';
import ContactForm from './pages/ContactForm';
import MessageProvider from './Context/MessageContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import AuthPage from './pages/Auth';
import SignUpPage from './pages/Signup';
import SignInPage from './pages/Signin';
import ForgotPassPage from './pages/ForgotPass';
import AuthService from "./auth";
import ChatBot from './pages/Chatbot';
import Logout from './Components/Logout';

// Middleware component to check if the user is authenticated
const AuthenticatedMiddleware = ({element: Element}) => {
  const isAuthenticated = AuthService.isLoggedIn(); // Replace with your actual authentication logic

  if (isAuthenticated) {
      return <Element/>;
  } else {
      // Redirect
      return <Navigate to="/logout"/>;
  }
};

const AuthenticatedRoleMiddleware = ({element: Element}) => {
  const isAuthenticated = AuthService.isLoggedIn(); // Replace with your actual authentication logic
  const user = AuthService.getCurrentUser();

  if (isAuthenticated && user.role === 1) {
      return <Element/>;
  } else {
      // Redirect
      return <Navigate to="/logout"/>;
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/signin",
    element: <SignInPage />
  },
  {
    path: "/signup",
    element: <SignUpPage />
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/forgotpass",
    element: <ForgotPassPage />
  },
  {
    path: "contact",
    element: <ContactForm/>,
  },
  {
    path: "/chatbot",
    element: <AuthenticatedMiddleware element={ChatBot} />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MessageProvider>
    <RouterProvider router={router} />
  </MessageProvider>
  
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
