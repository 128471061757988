import React from 'react'
import Header from '../Components/Header'
import '../auth.css'
import icon from '../img/icon.png'
import { Link } from 'react-router-dom'
import AuthPage from './Auth'

function ForgotPassPage() {
    return (
        <AuthPage>
            <h1 className='text-white text-center pt-10 text-lg'>Recover your <b>DongMi</b> account</h1>
            <h4 className='text-center text-white text-sm'>Kindly fill in your correct email address</h4>
            <div className=" flex flex-col  mt-5 ">
                <form className="rounded px-5 pt-5 pb-2 mb-1">
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="username">
                            Email address
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" id="username" type="email" placeholder="john@gmail.com" />
                    </div>

                    <div className=" mb-2">
                        <button className="block w-full bg-white text-black hover:bg-white font-bold py-2 px-4  focus:outline-none focus:shadow-outline text-xs mb-2" type="button">
                            Submit
                        </button>
                        <Link to={'/signin'} className="block w-full text-center bg-gradient-to-r from-purple-800 to-white-500 hover:bg-black text-white font-bold py-2 px-4  focus:outline-none focus:shadow-outline text-xs" type="button">
                            Go Back
                        </Link>
                    </div>
                </form>

            </div>
            <br />
        </AuthPage>
    )
}

export default ForgotPassPage