import React from 'react'
import Header from '../Components/Header'
import avatar from "../img/avatar.png";
import timeStamp from "../Components/Messages"
import Chat from "../Components/Chat";
import Messages from "../Components/Messages";

function ChatBot() {
    return (

        <div style={{ backgroundColor: "white" }}>
            <div>
                <Header />
                <div className="chats" >

                    <div className="avatar">
                        <img src={avatar} alt="" />
                    </div>
                    <div className="chat_text">
                        <p className="text">DongMi</p>
                        <div
                            className="chat_area bg-white shadow resizable"
                            // onChange={handleChange}
                            // rows="1"
                            //   ref={textAreaRef}
                            disabled
                        >Welcome to DongMi!</div>
                        <p className="time">{timeStamp}</p>
                    </div>
                </div>
                <div className="chats">

                    <div className="avatar">
                        <img src={avatar} alt="" />
                    </div>
                    <div className="chat_text">
                        <p className="text">DongMi</p>
                        <div
                            className="chat_area bg-white shadow resizable"
                            // onChange={handleChange}
                            // rows="1"
                            //   ref={textAreaRef}
                            disabled
                        >DongMi is your #1 virtual life assistant app. I am here to help make
                            your life easier. I can run multiple errands in real time for you or your
                            loved ones. For example: you can ask me to order for food for you via any
                            of your favourite restaurants or call a laundromat to come pick up your
                            clothes for laundry, i can do a lot more but lets get started.</div>
                        <p className="time">{timeStamp}</p>
                    </div>
                </div>
                <div className="chats" style={{ backgroundColor: "white" }}>

                    <div className="avatar">
                        <img src={avatar} alt="" />
                    </div>
                    <div className="chat_text">
                        <p className="text">DongMi</p>
                        <div
                            className="chat_area bg-white shadow"
                        >Respond with 'commands' to see what i can do.</div>
                        <p className="time">{timeStamp}</p>
                    </div>
                </div>
                <Chat />
                <Messages />
            </div>
        </div>
    )
}

export default ChatBot