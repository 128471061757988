import React, { useState } from 'react'
import "./Header.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faIdBadge } from '@fortawesome/free-solid-svg-icons'
import logo from '../img/logo.png'
import { FiPhone } from "react-icons/fi";
import { RiContactsLine, RiMenu2Fill } from "react-icons/ri";
import { Link } from 'react-router-dom';

// import { useState } from 'react';

const Header = () => {
  const [openModal, setOpenModal] = useState(false)

  const toggleModal = () => {
    setOpenModal(!openModal);
  }

  return (
    <>
      <header className="header">
        <div className="container w-full  bg-gray-100 shadow-xl">
          <div className="logo">
            <Link to="/chatbot"><img src={logo} alt=""></img></Link>
          </div>
          <div className="navlink">
            <a href="https://wa.me/+2349168570510?text=Hello, I am a DongMi Customer and my Order Number Is:"> <FiPhone size="1.5rem" /></a>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;