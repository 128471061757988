import { useEffect } from 'react';
import AuthService from '../auth';
import { useNavigate } from 'react-router-dom';

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        AuthService.logout();
        navigate('/signin');
    }, [navigate]);

    return null; // or return a loading indicator if needed
}

export default Logout;
