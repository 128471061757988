class AuthService {
    constructor() {
      this.tokenKey = "token";
      this.userKey = "user";
      this.loggedInKey = "loggedin";
      this.expiresKey = "expires";
    }
  
    getCurrentUser() {
      const userJSON = localStorage.getItem(this.userKey);
      return userJSON ? JSON.parse(userJSON) : {"user":null};
    }
  
    getToken() {
      return localStorage.getItem(this.tokenKey);
    }
  
    isTokenValid() {
      const tokenExpiresAt = localStorage.getItem(this.expiresKey);
      const currentTime = new Date().getTime();
  
      return tokenExpiresAt && tokenExpiresAt >= currentTime;
    }
  
    isLoggedIn() {
      if (!localStorage.getItem(this.tokenKey)) {
        return false;
      }
  
      return true;
    }
  
    adminOnly() {
      const currentUser = this.getCurrentUser();
      if (currentUser && currentUser.role === 1) {
        window.location = "/dashboard";
      }
    }
  
    logout() {
      localStorage.removeItem(this.tokenKey);
      localStorage.removeItem(this.userKey);
      localStorage.removeItem(this.loggedInKey);
      localStorage.removeItem(this.expiresKey);
    }
  }
  
  const instance = new AuthService();
  
  export default instance;
  