import React from 'react'
import Header from '../Components/Header'

import icon from '../img/icon.png'
import { Link, Navigate } from 'react-router-dom'
import AuthService from '../auth'

function AuthPage({ children }) {

    const isAuthenticated = AuthService.isLoggedIn(); // Replace with your actual authentication logic

    if (isAuthenticated) {
        return <Navigate to={'/chatbot'} />
    }

    return (
        <div style={{ backgroundColor: "#8c51ff", height: "200vh", border: "1px solid #8c51ff" }}>
            <div style={{ paddingTop: 100 }}><img src={icon} width={100} style={{ borderRadius: "100px", textAlign: "center", margin: "0px auto 0px" }} /></div>
            {children}
            <h4 className='text-white text-center pt-10 text-sm'>&copy; 2023 <br /> DongMi Technologies Limited </h4>

        </div>
    )
}

export default AuthPage