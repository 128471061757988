import React, { useState } from 'react'
import Header from '../Components/Header'
import '../auth.css'
import icon from '../img/icon.png'
import { Link, useNavigate } from 'react-router-dom'
import AuthPage from './Auth'
import { emailRegex } from '../utils'
import sendApiRequest from '../api'

function SignUpPage() {

    const [loading, setLoading] = useState(false);
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [username, setUsername] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false)
    const [errors, setErrors] = useState([]);
    const [apiErrors, setApiErrors] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState([]);
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {

        setLoading(true);
        setApiSuccess(false);
        setApiErrors(false);
        setErrors([])

        const formData = {
            email: email,
            password: password,
        };

        const newErrors = [];

        if (formData.email === "") {
            newErrors.push("Please enter your email address");
        } else if (!emailRegex.test(email)) {
            newErrors.push("Please enter a valid email address");
        }

        if (formData.password === "") {
            newErrors.push("Please enter your password");
        } else if (formData.password.length < 6) {
            newErrors.push("Password must be at least 6 characters long");
        }

        if (newErrors.length > 0) {
            setErrors(newErrors);
            setApiSuccess(false);
            setLoading(false);
            return;
        }

        // Login request
        (async () => {
            const postData = await sendApiRequest("post", "/auth/register", formData);
            if (postData !== null) {
                if (postData.errors || postData.error) {
                    setApiErrors(true);
                    setApiSuccess(false);
                    setApiErrorMessage([postData.error]);
                }

                if (postData.token) {
                    setApiSuccess(true);
                    setApiSuccessMessage("You are now signed in. Redirecting in 1 second..");

                    const { token, user, expires } = postData;

                    localStorage.setItem("token", token);
                    localStorage.setItem("user", JSON.stringify(user));
                    localStorage.setItem("loggedin", true);
                    localStorage.setItem("expires", expires);

                    return navigate("/chatbot");
                }
            } else {
                setApiErrorMessage(["Error occurred while submitting data to the API."]);
                setApiErrors(true);
                setApiSuccess(false);
                setLoading(false);
            }
        })();

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <AuthPage>
            <h1 className='text-white text-center pt-10 text-lg'>Create a <b>DongMi</b> account</h1>
            <h4 className='text-center text-white text-sm'>Kindly fill in your correct details</h4>
            <div className=" flex flex-col  mt-5 ">
                <form className="rounded px-5 pt-5 pb-2 mb-1">
                <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="username">
                            Firstname
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="johnthegoodguy" />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="username">
                            Lastname
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="johnthegoodguy" />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="username">
                            Username
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="johnthegoodguy" />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
                            Email address
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="john@gmail.com" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
                            Phone number
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="john@gmail.com" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-gray-900 mb-1 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" onChange={(e) => setPassword(e.target.value)} />
                    </div>

                    <div className="mb-5">
                        <label className="flex items-center text-white text-sm font-bold mb-2">
                            <input type="checkbox" className="form-checkbox h-5 w-5 text-purple-500" onChange={(e) => setRememberMe(e.target.checked)} />
                            <span className="ml-2 text-xs">I agree to the Terms and Conditions</span>
                        </label>
                    </div>

                    <div className=" mb-2">
                        <button className="block w-full bg-white from-white text-black hover:bg-white  font-bold py-2 px-4  focus:outline-none focus:shadow-outline text-xs" type="button" onClick={handleSubmit}>
                            Sign Up
                        </button>
                    </div>

                    <div className="flex items-center justify-between mb-4">
                        <Link to={'/signin'} className="block w-full text-white bg-gradient-to-r from-purple-800 to-white-500 font-bold py-2 px-4 text-center focus:outline-none focus:shadow-outline text-xs" type="button">
                            Go Back
                        </Link>
                    </div>
                </form>

            </div>


        </AuthPage>
    )
}

export default SignUpPage