import { useContext, createContext, useState } from "react";
import avatar from "../img/avatar.png";



export const messageContext = createContext({});
 const MessageProvider = ({children}) =>{
    const items= localStorage.getItem("Messages")
    const [messages, setMessages] = useState(items !== null ? JSON.parse(items): []);
    return(

        <messageContext.Provider value={{messages, setMessages}}>
            
            {children}
            
        </messageContext.Provider >
    )
}

export default MessageProvider;