import Chat from "./Components/Chat";
import Header from "./Components/Header";
import Messages from "./Components/Messages";
import "./App.css"
import timeStamp from "./Components/Messages"
import "./Components/Messages.css";
import avatar from "./img/avatar.png";
import { useState, useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import ChatBot from "./pages/Chatbot";
import AuthPage from "./pages/Auth";
import icon from '../src/img/icon.png'
import SignInPage from "./pages/Signin";

function App() {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }, [])
  return (
    <>

      {
        loading ?
          <div className="App" style={{backgroundColor: "#8c51ff"}}>
            <>
              <img src={icon} style={{margin: "0px auto 0px", borderRadius: "50px"}} width={100}/>
            </>
          </div>
          :
         <SignInPage />
      }



    </>
  );
}

export default App;
